import React from "react"
import Layout from "../../components/_layout"
import SectionHeader from "../../components/_layout/section-header"
import FeaturesY from "../../components/features-y"
import Divider from "../../components/divider"
import Pattern from "../../components/pattern"
import { Box, Link } from "rebass"
import SEO from "../../components/seo"

const Manifesto = () => {
  return (
    <Layout>
      <SEO
        title="The values in which we believe"
        description="These are the values that define us, in which we believe and that we live for every day."
      />
      <SectionHeader title="Manifesto" />
      <Pattern opacity={0.2} />
      <Box bg="dark" color="white">
        <Divider
          theme="dark"
          firstLine="These are the values that define us, in which we believe and that we live for every day. They are the values that bind us as colleagues and as people."
          secondLine="This is what we are"
        />
        <FeaturesY
          theme="dark"
          features={[
            {
              title: ["We are the ones", "whose eyes shine"],
              bigTitle: "Passion",
              description:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In vitae magna rhoncus, ultrices lacus et, commodo augue. Nullam mollis, dolor eget fringilla mollis, nulla libero luctus ex, id pulvinar ligula tellus tincidunt tellus. Praesent feugiat sapien orci. Sed nec metus felis. Maecenas gravida quam vel rutrum pretium. ",
            },
            {
              title: ["We are the ones", "who never get tired"],
              bigTitle: "Energy",
              description:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In vitae magna rhoncus, ultrices lacus et, commodo augue. Nullam mollis, dolor eget fringilla mollis, nulla libero luctus ex, id pulvinar ligula tellus tincidunt tellus. Praesent feugiat sapien orci. Sed nec metus felis. Maecenas gravida quam vel rutrum pretium. ",
            },
            {
              title: ["We are the ones", "who never settle"],
              bigTitle: "Ambition",
              description:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In vitae magna rhoncus, ultrices lacus et, commodo augue. Nullam mollis, dolor eget fringilla mollis, nulla libero luctus ex, id pulvinar ligula tellus tincidunt tellus. Praesent feugiat sapien orci. Sed nec metus felis. Maecenas gravida quam vel rutrum pretium. ",
            },
            {
              title: ["We are the ones", "who thrive on challenges"],
              bigTitle: "Versatility",
              description:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In vitae magna rhoncus, ultrices lacus et, commodo augue. Nullam mollis, dolor eget fringilla mollis, nulla libero luctus ex, id pulvinar ligula tellus tincidunt tellus. Praesent feugiat sapien orci. Sed nec metus felis. Maecenas gravida quam vel rutrum pretium. ",
            },
            {
              title: ["We are the ones", "who never give up"],
              bigTitle: "Dedication",
              description:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In vitae magna rhoncus, ultrices lacus et, commodo augue. Nullam mollis, dolor eget fringilla mollis, nulla libero luctus ex, id pulvinar ligula tellus tincidunt tellus. Praesent feugiat sapien orci. Sed nec metus felis. Maecenas gravida quam vel rutrum pretium. ",
            },
            {
              title: ["We are the fast fish", "who eats the slow fish"],
              bigTitle: "Agility",
              description:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In vitae magna rhoncus, ultrices lacus et, commodo augue. Nullam mollis, dolor eget fringilla mollis, nulla libero luctus ex, id pulvinar ligula tellus tincidunt tellus. Praesent feugiat sapien orci. Sed nec metus felis. Maecenas gravida quam vel rutrum pretium. ",
            },
          ]}
        />
      </Box>

      <Divider
        firstLine="We are Spinup, we are a media tech company and the goals we set for the next years are extremely stimulating."
        secondLine={
          <>
            Do you wanna be a Spinupper?{" "}
            <Link href="/contact-us" color="secondary">
              Work with us
            </Link>
          </>
        }
      />
    </Layout>
  )
}

export default Manifesto
